
export default {
  name: 'RatingStar',

  props: {
    index: { type: Number, default: null },
    half: { type: Boolean, default: false },
    full: { type: Boolean, default: false },
    fontSizeMdThree: { type: Boolean, default: false },
  },

  methods: {
    onMouseover (e) {
      if (this.index) {
        // @todo jak beda polowki
        // const isFullHovered = e.offsetX > e.target.offsetWidth / 2

        // this.$emit('hover-rating', isFullHovered ? this.index : this.index - 0.5)
        this.$emit('hover-rating', this.index)
      }
    },

    onMouseout () {
      this.$emit('hover-rating', null)
    },

    onClick (e) {
      if (this.index) {
        // @todo jak beda polowki
        // const isFullHovered = e.offsetX > e.target.offsetWidth / 2

        // this.$emit('set-rating', isFullHovered ? this.index : this.index - 0.5)
        this.$emit('set-rating', this.index)
      }
    },
  },
}
